import { Link, NavLink, useLocation } from "@remix-run/react"
import { useState, useEffect } from "react";
import { classNames } from "~/lib/util";
import NetworkSelector from "../network-selector";
import Logo from "./logo"
import { useRdt } from "~/rdt/hooks/useRdt";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { is } from "drizzle-orm";

declare global {
   namespace JSX {
      interface IntrinsicElements {
         'radix-connect-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
      }
   }
}

export default function Header() {
	const [menuOpen, setMenuOpen] = useState(false);
   const [showingPools, setShowingPools] = useState(false);
   const [showingTokens, setShowingTokens] = useState(false);
   const location = useLocation();
   const rdt = useRdt();

   // set button layout
   useEffect(() => {
      rdt?.buttonApi.setTheme('white');
      rdt?.buttonApi.setMode('light');
   }, []);

	function toggleMenu() {
      setMenuOpen(!menuOpen);
   }

   function closeMenu() {
      if (!menuOpen) {
         return;
      }

      setMenuOpen(false);
   }
	
	return (
    <header >
      <nav className="relative flex items-center justify-end px-4 pb-3 pt-6 w-screen">
        
          <radix-connect-button />
      </nav>
    </header>
  );
}