import { MetaFunction } from "@remix-run/cloudflare";
import { Meta, Links, Outlet, ScrollRestoration, Scripts, LiveReload } from "@remix-run/react";
import Footer from "~/components/layout/footer";
import Header from "~/components/layout/header";
import TransactionNotification from "~/components/transaction-notification";
import RadixProvider from "~/rdt/radixProvider";

import '../styles/app.css';

export function links() {
   return [
      { rel: 'shortcut icon', type: 'image/svg+xml', href: 'https://eblockchainconvention.com/wp-content/uploads/2020/11/Untitled-design-33-300x300.png' },
      { rel: 'mask-icon', sizes: 'any', href: 'https://eblockchainconvention.com/wp-content/uploads/2020/11/Untitled-design-33-300x300.png', color: '#5D0FC0' },
   ];
}

export const meta: MetaFunction = () => {
   return [{ title: 'EBC10 Hackathon' }];
};

export default function App() {
   return (
     <html lang="en" className="bg-neutral-700">
       <head>
         <meta charSet="utf-8" />
         <meta name="viewport" content="width=device-width, initial-scale=1" />
         <meta name="theme-color" content="#3d0283" />
         <Meta />
         <Links />
       </head>
       <body className=" min-h-screen  pb-4 text-white ">
        <video src="/assets/radix-animated-bg.mp4" autoPlay muted loop preload="auto" className="absolute w-screen h-screen object-cover z-[-2]"></video>
         <RadixProvider>
          <Header />
           <main className="z-10 pt-5 lg:pt-12">
             <Outlet />
             <TransactionNotification />
             <ScrollRestoration />
           </main>
         </RadixProvider>

         <Scripts />
       </body>
     </html>
   );
}
